main {
    position:absolute;
    top:45%;
    transform:translateY(-50%);
    width: 100%;
}

h1, p {
    text-align: center;
}

span, a {
    color: white;
    background-color: black;
}

a {
    position: relative;
    text-decoration: none;
  }
  
a:hover {
    background-color: #fff;
    color: #000;
    text-decoration: underline;
}

footer {
    position:absolute;
    bottom:15%;
    text-align: center;
    width: 100%;
}

.source>a {
    font-size: 12px;
    background-color: #fff;
    color: grey;
}